import { nextTick } from 'vue'
import { useCartStore } from '@/stores/cart'
import { useAnalytics } from '@/composables/useAnalytics'
import { useCurrentLocale } from '@/composables/useCurrentLocale'

export default {
  emits: ['proceed-to-payment'],
  data() {
    return {
      emailOrPhone: null,
      password: null,
      withRedirect: true,
    }
  },
  methods: {
    async loginMx(provider) {
      try {
        const { $currentUser, $api } = useNuxtApp()
        const router = useRouter()
        // Log user with its credentials
        if (!this.isLoading && this.emailOrPhone && this.password) {
          this.error = null
          await this.spRequest({
            req: $api().user.login(this.emailOrPhone, this.password),
          }).then(async response => {
            if (response?.user !== null && response?.user !== undefined) {
              await $currentUser().login(response.token, response.user)
              // console.log('login', response.user)
              if (this.compact) {
                this.$emit('proceed-to-payment')
              } else {
                let redirect_url = this.$route.query.redirect || '/'
                if (this.withRedirect) {
                  await nextTick(() => {
                    setTimeout(() => {
                      router.push({
                        path: redirect_url,
                      })
                    }, 500)
                  })
                }
              }
              this.loginEventToAnalytics('phone number')
            } else {
              const { country } = useCurrentLocale()
              this.error = this.$te(`backendError.${response?.message}`)
                ? this.$t(`backendError.${response.message}`, {
                    phone: country?.phoneNumber,
                  })
                : this.$t('backendError.unknown', {
                    phone: country?.phoneNumber,
                  })
            }
          })
        } else if (!this.isLoading && provider) {
          this.error = null
          await $currentUser().login(provider.token, provider.user)
          // console.log('login', response.user)
          if (this.compact) {
            this.$emit('proceed-to-payment')
          } else {
            let redirect_url = this.$route.query.redirect || '/'
            if (this.withRedirect) {
              await nextTick(() => {
                setTimeout(() => {
                  router.push({
                    path: redirect_url,
                  })
                }, 500)
              })
            }
          }
          this.loginEventToAnalytics('google')
        }
      } catch (e) {
        console.log(e)
      }
    },
    goToResetPassword() {
      const { $api } = useNuxtApp()
      if (!this.emailOrPhone) {
        this.error = this.$t('backendError.phone_input_empty')
        return
      }
      this.spRequest({
        req: $api().user.forgotPassword(this.emailOrPhone),
      }).then(success => {
        if (success) {
          // Succeed
          this.$store.commit('activation/setEmailOrPhone', this.emailOrPhone)
          this.$store.commit('activation/setForgotPassword', true)
          // Go to user activation (SMS or Email validation)
          if (this.compact) {
            this.forgotPassword = true
          } else {
            const localePath = useLocalePath()
            this.$router.push(localePath('login-activation'))
          }
        } else {
          const { country } = useCurrentLocale()
          this.error = this.$t('backendError.unknown', {
            phone: country?.phoneNumber,
          })
        }
      })
    },
    async loginInCart(provider) {
      const localePath = useLocalePath()
      const { $cart, $currentUser, $api } = useNuxtApp()

      if (!this.collapsed) {
        // this.$auth.options.redirect = false
        await this.loginMx(provider)
        if (!this.error) {
          $cart()
            .synchronize($currentUser(), $api())
            .then(() => {
              this.$router.push(localePath('cart-checkout'))
            })
            .catch(error => {
              const cartStore = useCartStore()
              if (error.response) {
                cartStore.setSyncError(error.response.data)
              } else {
                cartStore.setSyncError(error)
              }
              this.$router.replace(localePath('cart'))
            })
            .finally(() => {
              // this.$auth.options.redirect = {
              //   login: '/login',
              //   logout: '/',
              //   home: '/',
              // }
            })
        }
      }
    },
    loginEventToAnalytics(method) {
      const { $currentUser } = useNuxtApp()
      const { analytics, events } = useAnalytics()
      analytics.sendEvent(events.LOGIN, {
        method: method,
        form_user_type: $currentUser().isPro ? 'pro' : 'individual',
      })
      analytics.sendEvent(events.LOGIN_STATUS, $currentUser())
    },
  },
}
