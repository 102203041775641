<template>
  <div :data-test-id="elementTest">
    <div v-show="emailOrPhoneLabel" class="input-email-or-phone-label" :data-test-id="elementTest + '-label'">
      <p v-show="fieldFocus" :data-test-id="elementTest + '-label-text'">
        {{ $t('fields.emailOrPhone') }}
      </p>
    </div>
    <div class="input-email-or-phone" :class="classVariant" :data-test-id="elementTest + '-wrapper'">
      <SelectLocation
        v-show="isPhone"
        v-model="location"
        :select-country="selectCountry"
        :element-test="elementTest + '-select-location'"
      />
      <div class="input-email-or-phone-wrapper">
        <div
          v-if="location"
          v-show="isPhone"
          class="input-email-or-phone-wrapper-dialingcode"
          :data-test-id="elementTest + '-dialingcode'"
        >
          <p>{{ location.dialingCode }}</p>
        </div>
        <InputField
          v-model="phone"
          class="input-email-or-phone-wrapper-input"
          :class="isPhone ? 'no-p-l' : ''"
          input-id="email-or-phone"
          input-type="text"
          :element-test="elementTest + '-input'"
          :placeholder-value="$t('fields.emailOrPhone')"
          :class-variant="classVariant || 'bg-grey'"
          :error="error"
          :required="required"
          :input-rules="inputRules"
          :input-formatter="phoneOrEmailFormatter"
          @focus="fieldFocus = true"
          @blur="blur"
          @change="change"
          @on-value="setInput"
          @update:model-value="setInput"
        >
          <template #icon>
            <div v-if="!isPhone" class="icon-user u-bg-dark-blue" :data-test-id="elementTest + '-icon-user'" />
          </template>
        </InputField>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isValidPhoneNumber, isPossiblePhoneNumber, Metadata } from 'libphonenumber-js'
import { email } from '@vuelidate/validators'

import SelectLocation from '@/components/SelectField/SelectLocation'
import InputField from '@/components/InputField/InputField'

const props = defineProps({
  modelValue: { type: Object, default: () => {} },
  required: { type: Boolean, default: false },
  classVariant: { type: String, default: null },
  emailOrPhoneLabel: { type: Boolean, default: true },
  selectCountry: { type: Boolean, default: false },
  elementTest: { type: String, default: 'email-or-phone' },
})
const emit = defineEmits(['update:modelValue', 'is-email-or-phone-invalid', 'change'])

const location = ref(null)
const error = ref('')
const fieldFocus = ref(false)
const isPhone = ref(false)
const phone = ref(null)

const inputRules = computed(() => {
  const validations = []
  if (props.required) {
    validations.push('required')
  }
  if (isPhone.value) {
    validations.push('numeric')
  } else {
    validations.push('email')
  }
  return validations
})

// watch(phone, e => {
//   setInput(e)
// })

const { tld } = useCurrentLocale()
onMounted(() => {
  const { countries } = useCountries()
  location.value = countries?.find(c => c.code?.toUpperCase() === tld)
  const metadata = new Metadata()
  metadata.selectNumberingPlan(tld)
  location.value.phoneMaxLength = (Math.max(...(metadata?.numberingPlan?.possibleLengths() || [])) + 1).toString()
  if (props.required) {
    emit('is-email-or-phone-invalid', true)
  }
  if (props.modelValue?.phone_local) {
    phone.value = props.modelValue.phone_local
  }
})

const blur = e => {
  fieldFocus.value = !e
}

const change = e => {
  emit('change', e)
}

const setInput = e => {
  const { $i18n } = useNuxtApp()
  const digitRegex = new RegExp('\\d', 'g')
  isPhone.value = isPossiblePhoneNumber(e, tld) || !!e.match(digitRegex)?.length === e

  if (!e) {
    error.value = $i18n.t('rules.required')
    emit('is-email-or-phone-invalid', true)
  }
  if (e?.length && !isPhone.value && !email.$validator(e)) {
    error.value = $i18n.t('subscribe.errors.email')
    emit('is-email-or-phone-invalid', true)
  } else if (e?.length && isPhone.value && !isValidPhoneNumber(e, tld) && !email.$validator(e)) {
    error.value = $i18n.t('subscribe.errors.phoneNumber')
    emit('is-email-or-phone-invalid', true)
  } else {
    error.value = ''
    if (isValidPhoneNumber(e, tld)) {
      let phoneInput = String(e)
      if (phoneInput[0] === '0') {
        phoneInput = phoneInput.substring(1, phoneInput.length)
      }
      const phone = {
        login: location.value?.dialingCode + phoneInput,
      }
      emit('is-email-or-phone-invalid', false)
      emit('update:modelValue', phone)
    } else {
      const emailInput = String(e)
      emit('is-email-or-phone-invalid', false)
      emit('update:modelValue', { login: emailInput })
    }
  }
}
const phoneOrEmailFormatter = e => {
  if (e?.length && isPhone.value) {
    return e.replaceAll(/\s+/g, '').substring(0, location.value?.phoneMaxLength)
  }
  if (e?.length) {
    return e.toLowerCase().trim()
  }
  return e
}
</script>

<style lang="scss">
.input-email-or-phone-label {
  display: flex;
  align-items: center;
  height: 28px;
  p {
    font-size: pxToRem(12px);
    color: var(--steel-grey);
    font-weight: 500;
    padding-left: $spacing-sm;
  }
}

.input-email-or-phone {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  background-color: var(--bg-grey);
  padding: $spacing-xxxs;
  height: 40px;
  border-radius: $spacing-xs;

  .sp-input-field .error {
    float: inherit;
    margin-bottom: 0;
    left: 0;
  }

  .sp-input-error {
    position: absolute;
  }

  .sp-input-field {
    margin-bottom: 0;
    width: 100%;
  }

  &-wrapper {
    align-items: center;
    display: flex;
    width: 100%;

    &-dialingcode {
      p {
        color: var(--steel-grey) !important;
        font-size: pxToRem(12px);
        font-weight: 500;
        line-height: 1;
      }
    }

    .sp-input {
      padding-left: $spacing-xs;
      padding-bottom: $spacing-xxxs;
    }
  }
  .no-p-l .sp-input.has-icon {
    padding-left: $spacing-sm !important;
  }
}
</style>
